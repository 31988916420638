
import Mixin from "@/mixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class ButtonPlans extends mixins(Mixin) {
  @Prop() backgroundColor;
  @Prop() backgroundColorRGB;
  @Prop() textColor;
  @Prop() text;
  @Prop() ctaWhatsapp;
  goToPlans() {
    const target = document.querySelector("#section-9-plans");
    this.$store.state.scrollbar.scrollTo(target);
  }

  goToWhatsApp() {
    this.whatsapp()
  }
  
  onHover(){
    console.log("onHover")
    document.documentElement.style.setProperty(
      "--button-color", 'rgb('+this.backgroundColorRGB+", 0.4)"
    );
  }
}
