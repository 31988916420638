
import { db } from "@/main";
import mixin from "@/mixin";
import { addDoc, collection, doc, setDoc } from "@firebase/firestore";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
@Component
export default class Contact extends mixins(mixin) {
  currencies = ["USD", "EUR", "COP"];
  budget = [];
  fields = {
    names: "",
    email: "",
    project_resume: "",
    currency: "USD",
    interests: [],
    budget: []
  };
  requiredRule: any = [];
  nameRules: any = [];
  emailRules: any = [];
  loading = false;
  showMessage = false;
  success = false;
  successMessage: string = this.$store.state.texts.contact.page.messages
    .success[this.$store.state.lang];
  failMessage: string = this.$store.state.texts.contact.page.messages.fail[
    this.$store.state.lang
  ];
  mounted() {
    this.requiredRule = [
      v =>
        v != undefined
          ? !!v ||
            this.$store.state.texts.rules.required[this.$store.state.lang]
          : ""
    ];
    this.nameRules = [
      v =>
        v != undefined
          ? !!v || this.$store.state.texts.rules.names[this.$store.state.lang]
          : ""
    ];
    this.emailRules = [
      v =>
        !!v ||
        this.$store.state.texts.rules.email.required[this.$store.state.lang],
      v =>
        /.+@.+\..+/.test(v) ||
        this.$store.state.texts.rules.email.format[this.$store.state.lang]
    ];
  }
  @Watch("fields.interests")
  onInterestsChanges(val) {
    console.log(val);
  }

  async sendRequest() {
    if ((this.$refs.formContact as any).validate()) {
      console.log("Correct");
      this.loading = true;
      const subColReff = collection(db, "website", "requests", "contact");
      await addDoc(subColReff, this.fields)
        .then(async () => {
          this.success = true;
          this.loading = false;
          this.showMessage = true;

        //   const pixelBody = {
        //   "data": [
        //       {
        //           "event_name": "Lead",
        //           "event_time": this.getUnixTime(),
        //           "action_source": "website",
        //           "user_data": {
        //               "lead_id": uuidv4()
        //           },
        //           "custom_data": {
        //               "lead_event_source": "Contact from contact details",
        //               "event_source": "contact_button",
        //           },
        //           "original_event_data": {
        //               "event_name": "Contact",
        //               "event_time": this.getUnixTime()
        //           }
        //       }
        //   ]
        // };

          
          // try {
          //   const fbToken = process.env.VUE_APP_FB_TOKEN;
          //   const pixelID = process.env.VUE_APP_PIXEL_ID;
          //   await axios.post(`https://graph.facebook.com/v18.0/${pixelID}/events`, pixelBody, {
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${fbToken}`,
          //     },
          //   });
          //   console.log("Pixel request success")
          // } catch (error) {
          //   console.log("Pixel request failed")
          //   console.log(error);
          // }
        })
        .catch(error => {
          this.success = false;
          this.loading = false;
          this.showMessage = true;
          console.log("Error contact: " + error);
        });
    } else {
      this.loading = false;
      this.showMessage = false;
      console.log("incorrect");
    }
  }
}
